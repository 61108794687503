import React, { useEffect, useRef, useState } from "react";

export const SealNumberComponent = React.forwardRef((props, ref) => {
  const { name, disabled, read_only, onChange, defaultValue } = props;

  // Using a ref to directly interact with the DOM element
  const inputRef = useRef(null);

  useEffect(() => {
    if (ref) {
      ref.current = inputRef.current; // Assigning the DOM element to the forwarded ref
    }
  }, [ref]);

  const formatLabel = (label) => {
    return label
      .replace(/<strong>/g, "")
      .replace(/<\/strong>/g, "")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  };

  return (
    <div className="form-group seal-number">
      <label className="form-label">
        <span dangerouslySetInnerHTML={{ __html: formatLabel(props.data.label) }}></span>
        {props.data.required && <span className="label-required badge badge-danger">Required</span>}
      </label>
      <input
        ref={inputRef} // Using the ref here
        type="text"
        className="form-control seal-number-input"
        name={name}
        disabled={disabled}
        readOnly={true}
        value={defaultValue} // Controlled component
        style={{ backgroundColor: "#494b4c", color: "white" }}
        placeholder="No Recorded Seal" 
      />
      <style>
        {`
          .seal-number-input::placeholder {
            color: white;
          }
        `}
      </style>
    </div>
  );
});