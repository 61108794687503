import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

export const SealedBrokenComponent = React.forwardRef((props, ref) => {
  const { name, defaultValue, onChange, data } = props;
  const options = data.options || [];
  console.log(data);
  const [selectedOption, setSelectedOption] = useState(
    defaultValue ||
      props.default || {
        label: "",
        options: [],
      }
  );
  const [checkedOptions, setCheckedOptions] = useState([]);

  const sealedId = `passOption_${Math.random().toString(36).substr(2, 9)}`;
  const brokenId = `failOption_${Math.random().toString(36).substr(2, 9)}`;

  const sealedRef = useRef(null);
  const brokenRef = useRef(null);

  useLayoutEffect(() => {
    if (ref && (props.default === "sealed" || props.default === "broken")) {
      ref.current = sealedRef.current;
    }
  }, []);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.value = JSON.stringify(selectedOption);
    }
  }, [selectedOption]);

  const handleOptionChange = (event) => {
    console.log(ref);
    const value = event.target.value;
    ref.current = event.target;

    console.log(ref);
    setSelectedOption((prevCheckedOptions) => {
      return {
        ...prevCheckedOptions,
        label: value,
      };
    });
    setCheckedOptions([]);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;
    setCheckedOptions((prevCheckedOptions) => {
      let updatedOptions;
      if (checked) {
        updatedOptions = [...prevCheckedOptions, value];
      } else {
        updatedOptions = prevCheckedOptions.filter(
          (option) => option !== value
        );
      }

      return updatedOptions;
    });

    setSelectedOption((prevCheckedOptions) => {
      let updatedOptions;
      if (checked) {
        updatedOptions = [...checkedOptions, value];
      } else {
        updatedOptions = checkedOptions.filter((option) => option !== value);
      }

      return {
        ...prevCheckedOptions,
        options: updatedOptions,
      };
    });
  };

  return (
    <div className="form-group sealed-broken">
      <div className="custom-control custom-radio-passfail option-inline">
        <input
          className="custom-control-input"
          name={name}
          type="radio"
          value="sealed"
          defaultValue={defaultValue}
          checked={selectedOption.label === "sealed"}
          onChange={handleOptionChange}
          id={sealedId}
          ref={sealedRef}
        />
        <label className="custom-control-label-passfail" htmlFor={sealedId}>
          Sealed
        </label>
      </div>
      <div
        className={
          "custom-control custom-radio-passfail option-inline" +
          (selectedOption.label === "broken" ? " checked" : "")
        }
      >
        <input
          className={"custom-control-input"}
          name={name}
          type="radio"
          value="broken"
          defaultValue={defaultValue}
          checked={selectedOption.label === "broken"}
          onChange={handleOptionChange}
          id={brokenId}
          ref={brokenRef}
        />
        <label className="custom-control-label-passfail" htmlFor={brokenId}>
          Broken
        </label>
      </div>
      {selectedOption.label === "broken" &&
        data.options &&
        data.showSealBrokenChecklistItems && (
          <div>
            {options.map((option) => (
              <div key={option.key} className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`option_${option.key}`}
                  defaultChecked={checkedOptions.includes(option.value)}
                  onChange={handleCheckboxChange}
                  name={`${name}_options`}
                  value={option.value}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`option_${option.key}`}
                >
                  {option.text}
                </label>
              </div>
            ))}
          </div>
        )}
    </div>
  );
});
