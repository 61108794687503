/**
 * v0 by Vercel.
 * @see https://v0.dev/t/wbOlWsKb0vo
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import "./codeModal.css";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "../ui/card";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { useEffect, useState } from "react";

export default function CodeModal({ submit }) {
  const [pin, setPin] = useState([]);

  useEffect(() => {
    console.log(pin);
  }, [pin]);
  return (
    <Card className="w-full max-w-md" style={{ backgroundColor: "white" }}>
      <CardHeader>
        <CardTitle>Enter 4-Digit Code</CardTitle>
        <CardDescription>
          Enter your 4-digit agency code to continue.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-4 gap-4">
          <Input
            type="text"
            maxLength={1}
            className="h-16 w-16 rounded-md border border-muted bg-background text-center text-2xl font-bold focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            onKeyUp={(e) => {
              if (e.target.value.length === 1 && e.key !== "Backspace") {
                e.target.nextElementSibling?.focus();
              }
            }}
            onKeyDown={(e) => {
              if (e.key >= "0" && e.key <= "9") {
                e.target.value = e.key;
                let newPin = [e.key];
                console.log(newPin);
                setPin(newPin);
              }
            }}
            inputMode="text"
            pattern="[0-9]*"
          />
          <Input
            type="text"
            maxLength={1}
            className="h-16 w-16 rounded-md border border-muted bg-background text-center text-2xl font-bold focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            onKeyUp={(e) => {
              if (e.target.value.length === 1 && e.key !== "Backspace") {
                e.target.nextElementSibling?.focus();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                e.target.value = "";
                e.target.previousElementSibling?.focus();
              } else {
                if (e.key >= "0" && e.key <= "9") {
                  e.target.value = e.key;
                  let value = e.key;
                  let newPin = pin;
                  newPin[1] = value;
                  console.log(newPin);
                  setPin(newPin);
                }
              }
            }}
            inputMode="text"
            pattern="[0-9]*"
          />
          <Input
            type="text"
            maxLength={1}
            className="h-16 w-16 rounded-md border border-muted bg-background text-center text-2xl font-bold focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            onKeyUp={(e) => {
              if (e.target.value.length === 1 && e.key !== "Backspace") {
                e.target.nextElementSibling?.focus();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                e.target.value = "";
                e.target.previousElementSibling?.focus();
              } else {
                if (e.key >= "0" && e.key <= "9") {
                  e.target.value = e.key;
                  let value = e.key;
                  let newPin = pin;
                  newPin[2] = value;
                  console.log(newPin);
                  setPin(newPin);
                }
              }
            }}
            inputMode="text"
            pattern="[0-9]*"
          />
          <Input
            type="text"
            maxLength={1}
            className="h-16 w-16 rounded-md border border-muted bg-background text-center text-2xl font-bold focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            onKeyUp={(e) => {
              if (e.target.value.length === 1 && e.key !== "Backspace") {
                e.target.nextElementSibling?.focus();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                e.target.value = "";
                e.target.previousElementSibling?.focus();
              } else {
                if (e.key >= "0" && e.key <= "9") {
                  e.target.value = e.key;
                  let value = e.key;
                  let newPin = pin;
                  newPin[3] = value;
                  console.log(newPin);
                  setPin(newPin);
                }
              }
            }}
            inputMode="text"
            pattern="[0-9]*"
          />
        </div>
      </CardContent>
      <CardFooter>
        <Button className="w-full submit-btn" onClick={() => submit(pin)}>
          Submit
        </Button>
      </CardFooter>
    </Card>
  );
}
