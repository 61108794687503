import React, { useEffect, useRef, useState } from "react";

export const SealComponent = React.forwardRef((selfProps, ref) => {
  const { name, defaultValue, disabled, ...rest } = selfProps;
  const inputRefs = useRef([]);
  const selfOptions = React.useRef({});

  // when user types in one of the inputs, the value of the input is logged. useeffect
  useEffect(() => {
    console.log("ref", ref);
  }, [inputRefs]);

  return (
    <div className="form-group">
      {selfProps.data.options.map((option, index) => {
        console.log(selfProps);
        // if (!option.key) {
        //   option.key = option.value;
        // }

        if (inputRefs.current[index] === undefined) {
          inputRefs.current[index] = React.createRef();
        }

        const this_key = `preview_${option.key}`;
        const props = {};
        props.name = selfProps.data.field_name;

        props.type = "text";
        // props.defaultValue = option.value;
        console.log(props);
        // props.value = option.value;
        props.ref = inputRefs.current[index];

        // every 3 seconds console.log the value of the input
        // setInterval(() => {
        //   console.log(props);
        // }, 9000);

        // //get the input ref
        // if (ref && option.key === defaultValue) {
        //   console.log("ref", ref);
        // }

        return (
          <div key={this_key}>
            <label className="form-label" htmlFor={`fid_${this_key}`}>
              {option.text}
            </label>
            <input
              id={`fid_${this_key}`}
              className="form-control"
              onChange={(e) => {
                // This code will run each time the value of the input changes
                // ref.current = inputRefs.current;
                // selfOptions.current[option.key] = e.target.value;

                console.log(ref);
              }}
              {...props}
            />
          </div>
        );
      })}
    </div>
  );
});
